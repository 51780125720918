import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import {Helmet} from "react-helmet";
import BitcoinSVG from "../vendor/svgs/Bitcoin.svg";
import EthSVG from "../vendor/svgs/Ethereum.svg";
import LightningSVG from "../vendor/svgs/Lightning.svg";

import "../scss/main.scss";

/// Layout
import Header from "../jsx/layouts/nav/Header";
import Footer from "../jsx/layouts/Footer";

export const query = graphql`
  query {
    variablesJson {
      bitcoin_donate_address
      eth_donate_address
      next_term_ends
    }
  }
`

  const CopyToClipboard = toCopy => {
    const el = document.createElement(`textarea`)
    el.value = toCopy
    el.setAttribute(`readonly`, ``)
    el.style.position = `absolute`
    el.style.left = `-9999px`
    document.body.appendChild(el)
    el.select()
    document.execCommand(`copy`)
    document.body.removeChild(el)
  }

function get_grade_class(grade) {
  return grade == 'NR' ? 'mini-grade-nr text-white text-center d-inline-block'
       : grade == 'F'  ? 'mini-grade-f text-white text-center d-inline-block'
       : grade == 'D'  ? 'mini-grade-d text-white text-center d-inline-block'
       : grade == 'C'  ? 'mini-grade-c text-white text-center d-inline-block'
       : grade == 'C+' ? 'mini-grade-cp text-white text-center d-inline-block'
       : grade == 'B-' ? 'mini-grade-bm text-white text-center d-inline-block'
       : grade == 'B'  ? 'mini-grade-b text-white text-center d-inline-block'
       : grade == 'B+' ? 'mini-grade-bp text-white text-center d-inline-block'
       : grade == 'A-' ? 'mini-grade-am text-white text-center d-inline-block'
       : grade == 'A'  ? 'mini-grade-a text-white text-center d-inline-block'
       : grade == 'A+' ? 'mini-grade-ap text-white text-center d-inline-block'
       : 'mini-grade-nr text-white text-center d-inline-block';
}

export default function Home({ data }) {
  
  // console.log(data)
  return (
    <>
      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width,initial-scale=1"/>
        <script defer data-domain="electbitcoin.org" src="https://data.electbitcoin.org/js/script.js"></script>
        <title>ElectBitcoin.org - About</title>
      </Helmet>
      <Header />
      <div className="content-body">
        <div className="container-fluid pt-2">
          <div class="text-center pb-2">
            <a href="https://twitter.com/ElectBitcoinOrg" target="_blank" class="badge badge-info badge-rounded">
              <i class="lab la-twitter scale3"></i> Follow @ElectBitcoinOrg for updates when new records are added!
            </a>
          </div>
          <div className="row">
          	<div className="col-xl-9 col-xxl-9 col-lg-9 col-md-12 order-lg-0 order-md-1 order-sm-1 order-1">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card bg-white" id="about">
                    <div className="card-body">
                      <p>
                        <i className="fas fa-flag-usa fs-30 scale5 text-dark mb-1 mr-4 float-left"></i>
                        The <Link to="/"><strong className="text-primary">ElectBitcoin.org Project</strong></Link> was created to crowdsource and archive US Politicians' records on <strong>Bitcoin, Cryptocurrencies, and Blockchain Technology</strong>. According to a recent NYDIG study, over 46 million US adults own Bitcoin in 2021. Our mission is to inform this quickly-growing group of voters and empower them to vote for their financial future.
                      </p>
                      <div id="record-timeline" className="widget-timeline">
                        <ul className="timeline">
                            <li key="red">
                              <div className="timeline-badge red"></div>
                              <span className="timeline-panel red">
                                <p>
                                  The United States simply cannot afford to let China, Russia, or any other country lead in this new, rapidly-growing and job-creating industry that will shape global finance in the decades to come.
                                  Our elected representatives must understand that, similar to The Internet in the 1990s, careful and thoughtful regulations will allow this new industry to grow and thrive within our nation, while heavy-handed regulations will kill US jobs and push these innovative technology companies overseas.
                                </p>
                                <p>
                                  This emerging asset class and technology allow Americans and American Businesses to transact digitally in a secure and completely transparent way without relying on any middlemen or gatekeepers: the ultimate financial freedom. Bitcoin & Cryptocurrencies were built for the people, by the people.
                                </p>
                              </span>
                            </li>
                            <li key="blue">
                              <div className="timeline-badge blue"></div>
                              <span className="timeline-panel blue">
                                <p>
                                  Additionally, Bitcoin and Cryptocurrencies provide an opportunity to level the playing field for low-income and minority groups that have historically been preyed upon by many of our county's banks and underrepresented in, or completely shut out of, the US financial system. 
                                  Bitcoin, DeFi (Decentralized Finance), and other Blockchain-based financial technologies are inherently unbiased, unlike banks that have been shown to pracice <a href="https://en.wikipedia.org/wiki/Redlining" target="_blank">'<strong className="text-primary">Redlining</strong>'</a> and other forms of discrimination against minority and protected groups.
                                </p>
                                <p>
                                  A recent <a href="https://www.fdic.gov/analysis/household-survey/2019report.pdf" target="_blank">FDIC Survey</a> showed that a staggering 13.8% of Black households and 12.2% of Hispanic households in the United States do not have a bank account. Bitcoin and Blockchain technologies have the abilitiy to empower individuals to take direct control of their finances and "bank the unbanked."
                                </p>
                              </span>
                            </li>
                        </ul>
                      </div>
                      <p>
                        These are issues that are important to a growing number of Americans and that have wide bipartisan support. This project's goal is to make it easy for voters to determine which of our elected officials stand with us as we look towards the future, and which of them remain stuck in the past.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="card bg-light" id="get-involved">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title"><i className="fas fa-users"></i> Get Involved!</h4>
                    </div>
                    <div className="card-body fs-14">
                      <p>
                        <i className="far fa-file-alt scale5 text-dark mb-0 mr-3 float-left"></i>
                        <strong>Contribute: </strong>The easiest and most important way that users can get involved with the project is by contributing items to the records of politicians.
                        If you see a politician mention Bitcoin, Cryptocurrencies, or Blockchain Technology in a Tweet, on TV, quoted in a reputable news article, or vote in important legislation, be sure to submit it!
                        Submitted items must be 100% fact-based and free of all opinions and must have a source link that backs up the submission for reference in order to be approved by our moderators.
                      </p>
                      <p>
                        <i className="fas fa-user-shield scale5 text-dark mb-0 mr-3 float-left"></i>
                        <strong>Moderate: </strong>All of our moderators are volunteers that work hard to ensure only factual and opinion-free entries make it onto the ElectBitcoin.org records.
                        If you are passionate about Bitcoin and politics, have a working understanding of GitHub, and would like to volunteer a bit of your time, please reach out to us!
                      </p>
                      <p>
                        <i className="fas fa-bullhorn scale5 text-dark mb-0 mr-3 float-left"></i>
                        <strong>Volunteer: </strong>Volunteers spread the word about this project both in person and online. Encouraging members of the Bitcoin and Cryptocurrency communities 
                        to consider the ElectBitcoin.org Grade when making voting decisions. As we get closer to the upcoming {parseInt(data.variablesJson.next_term_ends.split('-')[0])-1} election, we will need volunteers more than ever. If you're interested in getting directly involved, let us know!
                      </p>
                      <p>
                        <i className="fas fa-tools scale5 text-dark mb-0 mr-3 float-left"></i>
                        <strong>Build: </strong>Are you a software engineer, graphic designer, or Bitcoin advocate that wants to help us extend this project? If so, get in touch! We are always looking for passionate
                        individuals that can help us make everything about ElectBitcoin.org better. From the website code itself, to logos, flyers, and other graphic design, to helping us manage our backend data pipeline, we
                        welcome any skills that you would like to contribute!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="card bg-white" id="grades">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title"><i className="fas fa-user-graduate"></i> Grading System</h4>
                    </div>
                    <div className="card-body">
                      <p>
                        <Link to="/"><strong className="text-primary">ElectBitcoin.org</strong></Link> currently grades politicians soley based on user-submitted items to each individual politicians record. Each item submitted to a record is assigned either a positive or negative sentiment.
                        A simple formula is then used to calculate the percentage of positive items and assign a numeric score to each individual: <strong>(Positive Items / Total Items)</strong>. <br/>This numeric score is then used to determine the letter grade, based on the following scale:
                      </p>
                      <div className="table-responsive">
                        <table className="verticle-middle table table-striped table-bordered">
                          <thead>
                            <tr className="text-center">
                              <th scope="col"># of Records</th>
                              <th scope="col" className="text-center"><span className={get_grade_class('A+')}>A+</span></th>
                              <th scope="col" className="text-center"><span className={get_grade_class('A')}>A</span></th>
                              <th scope="col" className="text-center"><span className={get_grade_class('A-')}>A-</span></th>
                              <th scope="col" className="text-center"><span className={get_grade_class('B+')}>B+</span></th>
                              <th scope="col" className="text-center"><span className={get_grade_class('B')}>B</span></th>
                              <th scope="col" className="text-center"><span className={get_grade_class('B-')}>B-</span></th>
                              <th scope="col" className="text-center"><span className={get_grade_class('C+')}>C+</span></th>
                              <th scope="col" className="text-center"><span className={get_grade_class('C')}>C</span></th>
                              <th scope="col" className="text-center"><span className={get_grade_class('D')}>D</span></th>
                              <th scope="col" className="text-center"><span className={get_grade_class('F')}>F</span></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center">1</td>
                              <td className="text-center">-</td>
                              <td className="text-center">-</td>
                              <td className="text-center">-</td>
                              <td className="text-center">-</td>
                              <td className="text-center">100%</td>
                              <td className="text-center">-</td>
                              <td className="text-center">-</td>
                              <td className="text-center">-</td>
                              <td className="text-center">0%</td>
                              <td className="text-center">-</td>
                            </tr>
                            <tr>
                              <td className="text-center">2 - 5</td>
                              <td className="text-center">-</td>
                              <td className="text-center">-</td>
                              <td className="text-center">-</td>
                              <td className="text-center">100%</td>
                              <td className="text-center">≥75%</td>
                              <td className="text-center">-</td>
                              <td className="text-center">≥60%</td>
                              <td className="text-center">≥50%</td>
                              <td className="text-center">&lt;50%</td>
                              <td className="text-center">-</td>
                            </tr>
                            <tr>
                              <td className="text-center">&gt;5</td>
                              <td className="text-center">100%</td>
                              <td className="text-center">≥90%</td>
                              <td className="text-center">≥85%</td>
                              <td className="text-center">≥80%</td>
                              <td className="text-center">≥75%</td>
                              <td className="text-center">≥70%</td>
                              <td className="text-center">≥60%</td>
                              <td className="text-center">≥50%</td>
                              <td className="text-center">≥40%</td>
                              <td className="text-center">&lt;40%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>
                        When ranking best and worst grades, we rank first by letter grade, then by percentage of positive record items, then by total number of record items. 
                        For example, if 2 politicians both have 'A+' letter grades, and both have 100% positive items on their record, the politician with the greater item count on their record will be ranked higher.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-xxl-3 col-lg-3 col-md-12 order-lg-1 order-md-0 order-sm-0 order-0">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card bg-dark" id="donate">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title text-white"><i className="fa la-donate"></i> Support Our Work</h4>
                    </div>
                    <div className="card-body fs-15 text-white">
                      <p>We don't show ads nor use referral links. We rely on generous donations to keep this project going.</p>
                      <p>If you are able, please consider donating. If you'd like to donate in another way, contact us.</p>
                      <a href="#" className="text-white" data-toggle="tooltip" data-placement="top" title="Click To Copy" id="btc-address" onClick={() => {CopyToClipboard(data.variablesJson.bitcoin_donate_address)}}><p><BitcoinSVG height="20" width="20" className="mb-1"/> Bitcoin: <br/><span className="text-monospace pre fs-13">{data.variablesJson.bitcoin_donate_address} <i className="las la-copy scale3" alt="Copy To Clipboard"></i></span></p></a>
                      <a href="#" className="text-white" id="Lightning" onClick={() => {}}><p><LightningSVG height="20" width="20" className="mb-1"/> Lightning: <br/><span className="text-monospace pre fs-13">Coming Soon</span></p></a>
                      <a href="#" className="text-white" data-toggle="tooltip" data-placement="top" title="Click To Copy" id="eth-address" onClick={() => {CopyToClipboard(data.variablesJson.eth_donate_address)}}><p><EthSVG height="20" width="20" className="mb-1"/> ETH & ERC-20: <br/><span className="text-monospace pre fs-13">{data.variablesJson.eth_donate_address} <i className="las la-copy scale3" alt="Copy To Clipboard"></i></span></p></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
